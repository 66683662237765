import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser, IUserProduct } from "../../types/user";

type IUserState = {
  user: IUser | null;
  loading: boolean;
};

const initialState: IUserState = {
  user: null,
  loading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLoading(state, actions: PayloadAction<boolean>) {
      return {
        ...state,
        loading: actions.payload,
      };
    },
    setUser(state, actions: PayloadAction<IUser | null>) {
      return {
        ...state,
        user: actions.payload,
      };
    },
    setUserFavoriteProducts(state, actions: PayloadAction<string[]>) {
      return {
        ...state,
        user: {
          ...state.user!,
          favoriteProductIds: actions.payload,
        },
      };
    },
    setUserProductsInCart(state, actions: PayloadAction<IUserProduct[]>) {
      return {
        ...state,
        user: {
          ...state.user!,
          userProductsInCart: actions.payload,
        },
      };
    },
  },
});

export default userSlice.reducer;
export const {
  setUser,
  setUserLoading,
  setUserFavoriteProducts,
  setUserProductsInCart,
} = userSlice.actions;
