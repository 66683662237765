// Styles
import { FavoriteBorderOutlined } from "@mui/icons-material";
import { Box, SxProps, Theme } from "@mui/material";
import { addProductIdIntoUserFavoriteProducts } from "../../helpers/addProductIdIntoUserFavoriteProducts";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { removeProductIdFromUserFavoriteProducts } from "../../helpers/removeProductIdFromUserFavoriteProducts";
import { useNavigate } from "react-router-dom";
import { IProduct } from "../../types/product";

interface IProps {
  position?: "absolute" | "static";
  sx?: SxProps<Theme>;
  product: string;
  isFavorite: boolean;
}

const FavoriteButton = ({
  position = "absolute",
  sx,
  product,
  isFavorite,
  
}: IProps) => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state: RootState) => state.currentUser);
 
  const favoriteProducts = localStorage.getItem("favoriteProducts")
    ? JSON.parse(localStorage.getItem("favoriteProducts") as string)
    : [];

    console.log(favoriteProducts)
  const handleFavoriteButtonClick = () => {
    console.log(localStorage.getItem("favoriteProducts"))
    localStorage.setItem("favoriteProducts", "[]");
   localStorage.setItem("favoriteProducts", JSON.stringify(
    [product, ...favoriteProducts]
   ));
  };
  return (
    <Box
      onClick={() => {}}
      sx={{
        backgroundColor: `${isFavorite ? "#FBB03B" : "#ffffff"}`,
        border: "1px solid #9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "38px",
        width: "38px",
        "&:hover svg": {
          fill: `${isFavorite ? "#ffffff" : "#FBB03B"}`,
          transition: "fill 0.2s linear",
        },
        position: position,
        right: "23px",
        top: "19px",
        ...sx,
      }}
    >
      <FavoriteBorderOutlined />
    </Box>
  );
};

export default FavoriteButton;
