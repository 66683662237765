import React, { useState, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { Typography, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import urls from "../../../constants/urls";
import { Category } from "../../../types/product";
// import autoplay

import Autoplay from "embla-carousel-autoplay";

export const EmblaCarousel = () => {
  const [emblaRef] = useEmblaCarousel([Autoplay()]);
  const [categories, setCategories] = useState<Array<Category>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(urls.items.most_purchased_categories);
        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="">
      <Typography variant="h6" gutterBottom>
        Most purchased categories
      </Typography>
      <div className="embla__mcategories" ref={emblaRef}>
        <div className="embla__mcategories__container">
          {loading ? (
            // Skeleton loaders
            <>
              <Skeleton
                variant="rectangular"
                width={300}
                height={200}
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
              <Skeleton
                variant="rectangular"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                }}
                width={300}
                height={200}
              />
              <Skeleton
                variant="rectangular"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                }}
                width={300}
                height={200}
              />
              <Skeleton
                variant="rectangular"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                }}
                width={300}
                height={200}
              />
              <Skeleton
                variant="rectangular"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                }}
                width={300}
                height={200}
              />
            </>
          ) : (
            categories.map((cat) => (
              <div className="embla__mcategories__slide" key={cat.id}>
                <div
                  className="embla__mcategories__slide__inner"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    src={cat.icon}
                    alt={cat.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "10px",
                      backgroundColor: "white",
                      width: "60%",
                      height: "20%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption" gutterBottom>
                      {cat.name}
                    </Typography>
                    <Link
                      to={`/products/${cat.slug}`}
                      style={{
                        color: "#FE6F1E",
                        textUnderlineOffset: "5px",
                        textDecoration: "underline",
                      }}
                    >
                      <Typography variant="caption">View more</Typography>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
