import { useEffect } from "react";

// Styles
import { Box, Stack } from "@mui/material";
import theme from "../../theme";
import { Skeleton } from "@mui/material";

// Components
import {
  ProductBreadcrumbs,
  ProductList,
  ProductsDrawer,
} from "../../components";

// Hooks
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../app/store";

const Products = () => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // get the category from the url
  }, [searchParams, pathname]);
  return (
    <Box
      sx={{
        marginTop: { xs: "83px", md: "29px" },
        paddingLeft: {
          xs: theme.padding?.pagePaddingXS + "px",
          lg: theme.padding?.pagePaddingLG + "px",
          xl: theme.padding?.pagePaddingXL + "px",
        },
        paddingRight: {
          xs: theme.padding?.pagePaddingXS + "px",
          lg: theme.padding?.pagePaddingLG + "px",
          xl: theme.padding?.pagePaddingXL + "px",
        },
      }}
    >
      <Stack direction="row" sx={{ columnGap: { md: "14px", lg: "34px" } }}>
        <ProductsDrawer />
        <ProductList />
      </Stack>
    </Box>
  );
};

export default Products;
