import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import { IProduct } from "../../types/product";
import FavoriteButton from "../FavoriteButton";
import { useEffect, useState } from "react";

interface IProps {
  product: IProduct;
  showFavoriteButton?: boolean;
  shadow?: boolean;
}

const CompactProductCard = ({
  product,
  shadow = false,
  showFavoriteButton = true,
}: IProps) => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const favoriteProducts = localStorage.getItem("favoriteProducts")
    ? JSON.parse(localStorage.getItem("favoriteProducts") as string)
    : [];
  const [isFavorite, setIsFavorite] = useState(false);

  const handleFavoriteButtonClick = () => {
    /*...same function body as in original ProductCard*/
  };
  const handleConfirmQuote = () => {
    window.open(`https://wa.me/${product?.vendor?.phone}`, "_blank");
    setOpenModal(false);
  };

  const handleClickCard = () => {
    navigate({
      pathname: `/product-detail`,
      search: `?id=${product.id}`,
    });
  };
  return (
    <Stack
      sx={{
        width: { xs: "90vw", sm768: "200px" },
        height: "300px",
        borderRadius: "6px",
        border: "1px solid #f0f0f0",
        cursor: "pointer",
        transition: shadow ? "box-shadow 0.1s linear" : "none",
        "&:hover": shadow && {
          boxShadow: "1px 3px 5px 0px rgba(175,171,171,.5)",
        },
      }}
      onClick={handleClickCard}
    >
      <Box
        sx={{
          position: "relative",
          "& .product-image": {
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            height: "200px",
            width: "100%",
            objectFit: "cover",
          },
          width: "100%",
        }}
      >
        <img
          className="product-image"
          src={product.icon ? product.icon : "defaultProductIconURL"}
          loading="lazy"
          alt={product.name}
        />
        {showFavoriteButton && (
          <FavoriteButton
            product={product}
            isFavorite={false}
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              width: "24px",
              height: "24px",
            }}
          />
        )}
      </Box>
      <Stack
        sx={{
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "2px",
          paddingBottom: "2px",
        }}
      >
        <Typography
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            fontSize: { xs: "10px", sm768: "12px" },
            fontWeight: theme.fontWeight.light,
            width: "100%",
            height: "35px",
          }}
        >
          {product.name}
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "14px", sm768: "13px" },
            fontWeight: theme.fontWeight.bold,
          }}
        >
          {product.item_class === "service"
            ? "Get a Quote"
            : Number(product.pricing_current).toLocaleString("en-US", {
                style: "currency",
                currency: "KES",
              })}
        </Typography>
      </Stack>
      {openModal && (
        <div
          className="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 z-[300]"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Get a Quote
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              You are about to be redirected to the vendor's WhatsApp for a
              quote. If you prefer to call them directly, their phone number is{" "}
              {product?.vendor}.
            </Typography>
            <Button
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmQuote}>Visit Vendor WhatsApp</Button>
          </Box>
        </div>
      )}
    </Stack>
  );
};

export default CompactProductCard;
