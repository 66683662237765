import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Pagination,
  CircularProgress,
  CardContent,
  Card,
  Avatar,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../app/store";
import { fetchProductsByCategories } from "../../features/products/productsSlice";
import useProducts from "../../hooks/useProducts";
import { useParams, useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import useVendorDetail from "../../hooks/useVendorDetail";
import { ProductCard } from "../../components";
import axios from "axios";
import urls from "../../constants/urls";
import { IProduct } from "../../types/product";
import { GifBoxOutlined, WhatsApp } from "@mui/icons-material";

const VendorProducts = () => {
  const { categorySearchParameters, user } = useSelector(
    (state: RootState) => state
  );

  const { vendor_slug } = useParams<{ vendor_slug: string }>();
  const slug = vendor_slug;

  const {
    vendor,
    status: vendorStatus,
    fetchVendorDetail,
  } = useVendorDetail(slug);

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const UrlSearchQuery = searchParams.get("search") || "";

  const [searchQuery, setSearchQuery] = useState(UrlSearchQuery);

  const [productsResponse, setProductsResponse] = useState<{
    count: number;
    next: string;
    previous: string;
    results: IProduct[];
  }>({
    count: 0,
    next: "",
    previous: "",
    results: [],
  });
  const products = productsResponse.results;

  const initialPage = searchParams.get("page") || 1;
  const [page, setPage] = useState(Number(initialPage));
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const fetchProducts = async () => {
    setStatus("loading");
    axios
      .get(
        urls.items.all +
          `?vendor=${vendor?.id}` +
          `&search=${searchQuery}` +
          `&page=${page}`
      )
      .then((res) => {
        setProductsResponse(res.data);
        setStatus("success");
      })
      .catch((err) => {
        setStatus("error");
      });
  };

  useEffect(() => {
    if (vendor) {
      fetchProducts();
    }
  }, [vendor, page, searchQuery]);

  const handleSearch = () => {
    const searchParams = new URLSearchParams();
    searchParams.set("search", searchQuery);
    setSearchQuery(searchQuery);
    console.log(searchQuery);
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {vendorStatus === "success" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              backgroundColor: "#F5F5F5",
              width: "100%",
              maxWidth: "300px",
              padding: "20px",
            }}
          >
            <Typography
              variant="h6"
              style={{ margin: "20px 0", textAlign: "center" }}
            >
              {vendor?.name}
            </Typography>
            <Avatar
              src={vendor?.icon}
              alt="Vendor Icon"
              sx={{
                width: 120,
                height: 120,
                marginBottom: "10px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              }}
            />
            <img
              src={vendor?.banner}
              alt="Vendor Banner"
              style={{ width: "100%", height: "auto", maxHeight: 300 }}
            />

            <Button
              startIcon={<WhatsApp />}
              style={{
                margin: "20px",
                backgroundColor: "#25D366",
                color: "white",
              }}
              href={
                "https://wa.me/" +
                vendor?.phone.replace("+", "").replace(" ", "")
              }
            >
              Message Vendor (WhatsApp)
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                width: "calc(100% - 48px)",
                maxWidth: "400px",
                marginRight: "8px",
                "& input": {
                  fontSize: "16px",
                  padding: "12px",
                },
              }}
            />

            <Button
              variant="contained"
              size="small"
              startIcon={<SearchIcon />}
              sx={{
                height: "40px",
                minWidth: "40px",
              }}
              onClick={handleSearch}
            />
          </Box>
          {status === "loading" && (
            <CircularProgress style={{ margin: "20px" }} />
          )}
          {status === "error" && (
            <div>Error loading products. Please try again.</div>
          )}
          {status === "success" && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                columnGap: "16px",
                rowGap: "35px",
                minHeight: "500px",
                width: "100%",
                maxWidth: "944px",
              }}
            >
              {products?.length > 0 ? (
                products.map((product, index) => (
                  <ProductCard product={product} key={index} />
                ))
              ) : (
                <Typography variant="body1">No products found.</Typography>
              )}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(productsResponse.count / 10)}
              page={page}
              onChange={(e, value) => setPage(value)}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VendorProducts;
